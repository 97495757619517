import request from '../utils/request.js'

//头部

export function getHead(industryId) {
	return request({
		url: '/website/brandtable/classifyFollowNumber/detail/' + industryId,
		method: 'get',
	})
}


// export function getHead(obj) {
// 	return request({
// 		url: '/website/brandtable/classifyFollowNumber/detail',
// 		method: 'post',
// 		data: obj
// 	})
// }


//品牌排行列表
export function getList(query) {
	return request({
		url: '/website/brandtable/classifyAllTopBrandList',
		method: 'get',
		params: query
	})
}

//行业关联
export function guanlian(industryId) {
	return request({
		url: '/website/brandtable/classifyRelationList/detail/' + industryId,
		method: 'get',
	})
}
// export function guanlian(obj) {
// 	return request({
// 		url: '/website/brandtable/classifyRelationList/detail',
// 		method: 'post',
// 		data: obj,
// 	})
// }



//十大品牌
export function pinpai(query) {
	return request({
		url: '/website/brandtable/classifyTop10BrandList',
		method: 'get',
		params: query
	})
}

//百科
export function baike(query) {
	return request({
		url: '/website/brandtable/getArticleTableList',
		method: 'get',
		params: query

	})
}